html {
  scroll-behavior: smooth;
  @apply min-h-screen;
}

body {
  @apply antialiased;
  @apply min-h-screen;
}

.content-wrapper {
  @apply px-6 lg:px-12;
}

.hyphens {
  hyphens: auto;
}

#nprogress {
  @apply pointer-events-none;

  .bar {
    @apply fixed z-50 top-0 left-0 w-full h-0.5;
    @apply bg-purple-light;
  }
}

:where(img) {
  @apply duration-200;
}

.openJobsBadge {
  @apply bg-purple text-purple ml-2;
  @apply text-15-23;
  @apply font-medium;
  @apply bg-opacity-25;
  @apply px-2 py-0;
  @apply rounded-sm block;

  &.light {
    @apply opacity-100;
    background-color: #c9cfe5;
  }

  &.badgeBlue {
    color: #707070;
    @apply bg-gray-lighter;
  }
}

* {
  p {
    @apply m-0;
  }
}
