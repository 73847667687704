.author-container p {
  margin: 0;
}

.swiper-slide {
  height: auto !important;
}

.careerWrapper .mediaWrapper {
  @media (max-width: 639px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  @media (min-width: 640px) and (max-width: 767px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
