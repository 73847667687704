/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-300italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/poppins-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins-v15-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
