body {
  @apply text-purple text-14-24 md:text-16-28;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  @apply font-medium;
  @apply mb-3.5;

  strong {
    @apply font-medium;
  }
}

h1,
.h1 {
  @apply text-32-42 lg:text-45-55;
}

h2,
.h2 {
  @apply text-26-35 lg:text-35-55;
}

h3,
.h3 {
  @apply text-18-27 lg:text-28-34;
}

h5 {
  @apply font-bold;
  @apply text-14-24 lg:text-16-28;
}

strong {
  @apply font-semibold;
}

blockquote {
  @apply text-32-42 lg:text-45-55;

  & footer {
    @apply text-14-24 md:text-16-28;
  }
}

p + p {
  @apply mt-8;
}

p + h2,
p + h3,
p + h4,
p + h5 {
  @apply mt-8 lg:mt-16;
}

ul {
  display: block;
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 40px;

  li {
    display: list-item;
  }
}

ol {
  display: block;
  list-style-type: decimal;
  margin: 1em 0;
  padding-left: 40px;

  li {
    display: list-item;
  }
}
